
.backgroisns{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	
}
.usercontainerbody{
    padding: 2rem 0 0 0;
    display: grid;
	place-items: center;
	min-height: 100vh;
	
	background-color: #111111c2;
}


.usercontainer {
	width: 380px;
	/*
	background: rgb(33,41,92);
background: linear-gradient(90deg, rgba(33,41,92,1) 0%, rgba(6,90,130,1) 50%, rgba(33,41,92,1) 100%);
	*/
	background-color: #fff;
	box-shadow: 0 15px 20px rgba(0,0,0,0.2);
	border-radius: 15px 15px 0 0;
	margin-bottom: 1rem;
}

.usercontainer .userheader {
	color: #fff;
	font-size: 35px;
	font-weight: 600px;
	text-align: center;
	line-height: 100px;
	background: #111111;
	border-radius: 12px 12px 0 0;
}

.usercontainer form {
	padding: 25px 30px;
}

.usercontainer form .field {
	position: relative;
	height: 50px;
	width: 100%;
	margin-top: 20px;
}

.usercontainer form .field input,
.usercontainer form .field button {
	width: 100%;
	height: 100%;
	border-radius: 25px;
	outline: none;
}

.usercontainer form .field button {
	border: none;
}

.usercontainer form .field input {
	border: 1px solid lightgrey;
	padding-left: 15px;
	border-bottom-width: 2px;
	transition: all 0.3s ease;
	font-size: 17px;
}

.usercontainer form .field input:focus,
.usercontainer form .field input:valid {
	border-color: #ed1864;
}

.usercontainer form .field input:focus ~ label,
.usercontainer form .field input:valid ~ label{
	top: 0%;
	font-size: 16px;
	color: #ed1864;
	background-color: #fff;
}
.act{
    cursor: pointer;
    color: #ed1864 !important;
}
.usercontainer form .field label {
	position: absolute;
	top: 50%;
	left: 15px;
	transform: translateY(-50%);
	transition: all 0.3s ease;
	pointer-events: none;
	font-size: 17px;
	color: #999;
}

.usercontainer form .form-link {
	color: #4158d0;
	margin-top: 20px;
	text-align: center;
}

.usercontainer form .form-link span {
	color: #888;
}

.usercontainer form .form-link .forgot-pass:hover,
.usercontainer form .sign-up a:hover {
	text-decoration: underline;
}

.usercontainer form .field button {
	color: #fff;
	background-color: #ed1864;
	font-size: 18px;
	font-weight: 500;
	padding-left: 0;
	cursor: pointer;
	transition: all 0.3s ease;
}

.usercontainer form .field button:active,
.usercontainer form .field button:hover {
	transform: scale(0.95);
	background: #111111;
	font-size: 19px;
}
