
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
  box-sizing:border-box;
  margin:0;
}

.wrapperclass {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-size:10px;
  height: 100vh;
  margin: -20px 0 50px;
}

.container-main {
  background-color: white;
  border-radius: 5px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
      0 10px 10px rgba(0,0,0,0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  height: 600px;
  min-width:370px;
}

.right-inner-container h2 {
  font-size:2rem;
  margin-bottom:1rem;
}
.form-container {
  display:flex;
}

.left-container {
  flex:1;
  height:480px;
  background-color:#01017a;
}

.jsjbssas h2{
  font-size: 1.3rem;
  padding: 1rem  0 0.5rem 1rem;
  text-decoration: underline;
}
.right-container {
  display:flex;
  flex:1;
  height:460px;
  background-color: white;
  justify-content:center;
  align-items:center;
}

.left-container {
  display:flex;
  flex:1;
  height:720px;
  justify-content:center;
  align-items:center;
    color:white;
}

.left-container p {
  font-size:0.9rem;
}

.right-inner-container {
  width:70%;
  height:80%;
  text-align:center;
}

.left-inner-container {
  height:50%;
  width:80%;
  text-align:center;
  line-height:22px;
}

.right-inner-container input, .right-inner-container textarea {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  font-size:0.8rem;
}

.right-inner-container input:focus, .right-inner-container textarea:focus{
  outline:1px solid #01017a;
}

.right-inner-container button {
  border-radius: 20px;
  border: 1px solid #01017a;
  background-color: #01017a;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor:pointer;
}

.right-inner-container button:hover {
  opacity:0.7;
}

.no-news-container {
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px auto;
  max-width: 400px;
}

.no-news-container h2 {
  color: #333;
}

.no-news-container p {
  color: #666;
}

.no-news-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.no-news-container button:hover {
  background-color: #0056b3;
}

.news-container {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 20px;
  margin: 2rem 0.8rem;
}

.news-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.news-image {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover;
}

.news-details {
  padding: 20px;
}

.news-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.publisher,
.publication-date {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}
@media only screen and (max-width: 800px) {

  .news-container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .left-container{
    display: none;
  }
  .lg-view {
    display:none;  
  }
  .news-container {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .sm-view {
    display:none;  
  }
}

form p {
  text-align:left;
}


